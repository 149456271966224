@tailwind base;

.unselectable {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    outline: none;
}


.input:focus+.label,
.input:active+.label,
.label.active {
    font-size: .75rem;
    transition: all 0.2s ease-out;
    top: -0.3rem;
    color: #6b7280;
}
.label {
    transition: all 0.2s ease-out;
    top: 0.4rem;
    left: 0;
}

@tailwind components;
@tailwind utilities;